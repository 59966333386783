<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <el-button type="primary" size="small" @click="add" v-right-code="'Systemlogisticsnode:Get'">新增</el-button>
                <el-button type="primary" size="small" @click="deleteMultiple" v-right-code="'Systemlogisticsnode:Delete'">删除</el-button>
                <el-button type="primary" size="small" @click="enable" v-right-code="'Systemlogisticsnode:Enable'">启用</el-button>
                <el-button type="primary" size="small" @click="disable" v-right-code="'Systemlogisticsnode:Enable'">停用</el-button>
            </div>
            <p slot="elList">
                <el-table ref="memberInfoListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" show-overflow-tooltip
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ProjectName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on(this.config.moduleEvent.initial, () => this.reloadPageList());
            this.Event.$on(this.config.moduleEvent.add, () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                Id: ''
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
                this.$emit("onSelectedRow2", val);
                this.$emit("onSelectedRow3", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/systemlogisticsnode/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/systemlogisticsnode/get", "get", { id: row.Id }, (data) => {
                    this.Event.$emit("getOrderItemList", row.Id);
                    this.Event.$emit("clearEditRuleOfLogisticsNodeForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add() {
                this.$ajax.send("omsapi/systemlogisticsnode/get", "get", {}, (data) => {
                    this.Event.$emit("getOrderItemList", '');
                    data.Result.MemberProductList = [];
                    //data.Result.Status=data.Result.Status.toString();
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            deleteRule() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }
                var This = this;
                var ids = This.Utils.selectionsToArr(
                    This.multipleSelection,
                    "Id"
                );
                
                this.Utils.confirm({
                    title: "确认",
                    content: "是否删除数据"
                }, () => {
                    
                    this.$ajax.send("omsapi/systemlogisticsnode/delete", "post", ids, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("操作成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消操作.", "info");
                });
            },
            enableData() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择模板数据.`, "error");
                    return false;
                }

                var isValid = true;
                this.multipleSelection.forEach(item => item.Status == 1 && (isValid = false));
                if (!isValid) {
                    this.Utils.messageBox(`只能选择停用状态的数据`, "error");
                    return false;
                }

                var _this = this;
                var ids = _this.Utils.selectionsToArr(
                    _this.multipleSelection,
                    "Id"
                );
                this.$ajax.send("omsapi/systemlogisticsnode/setStatus", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("模板启用成功.", "success");
                });
            },
            deactivateData() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择模板数据.`, "error");
                    return false;
                }

                var isValid = true;
                this.multipleSelection.forEach(item => item.Status == 0 && (isValid = false));
                if (!isValid) {
                    this.Utils.messageBox(`只能选择启用状态的数据`, "error");
                    return false;
                }

                var _this = this;
                var ids = _this.Utils.selectionsToArr(
                    _this.multipleSelection,
                    "Id"
                );
                this.$ajax.send("omsapi/systemlogisticsnode/setStatus", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("模板停用成功.", "success");
                });
            },
            deleteMultiple() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
                this.delete(ids);
            },
            delete(ids){
                var me = this;
                this.Utils.confirm({
                    title: "提示：",
                    content: "删除后不可恢复，请确认?"
                }, () => {
                    this.$ajax.send("omsapi/systemlogisticsnode/delete", "post", ids, (data) => {
                        me.onPageChange(me.queryParam);
                        me.Utils.messageBox("删除成功.", "success");
                    });
                }, () => {
                    me.Utils.messageBox("已取消.", "info");
                });
            },
            enable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return;
                }
                debugger
                var statusList = this.Utils.selectionsToArr(this.multipleSelection, "Status");
                if(statusList.indexOf(100)>-1){
                    this.Utils.messageBox(`存在启用数据.`, "error");
                    return;
                }
                this.able(100);
            },
            disable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return;
                }
                debugger
                var statusList = this.Utils.selectionsToArr(this.multipleSelection, "Status");
                if(statusList.indexOf(200)>-1){
                    this.Utils.messageBox(`存在停用数据.`, "error");
                    return;
                }
                this.able(200);
            },
            able(bizType) {
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
                this.$ajax.send("omsapi/systemlogisticsnode/enable", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox((bizType==100?"启用":"停用")+"成功.", "success");
                });
            },
        }
    }
</script>
<style>
</style>